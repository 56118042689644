.insights-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100svh - 4rem);
  padding: 1rem 2.5%;
  padding-top: 0;
}

@media screen and (min-height: 600px) {
  .insights-card-container {
    min-height: 30rem;
  }
}

.card-img2 {
  /* position: absolute; */
  width: 50%;
  height: 60%;
  padding: 1rem;
  max-height: 25rem;
}

.insight-card-description {
  width: 50%;
}

.insight-card-description>h1 {
  font-family: var(--montserrat);
  font-size: var(--h1-font-size);
  font-weight: 600;
  line-height: var(--line-height);
  padding: 1rem;
}

.insight-card-description>p {
  color: rgba(255, 255, 255, 0.70);
  text-align: left;
  font-family: var(--montserrat);
  font-weight: 400;
  line-height: var(--line-height);
  padding: 1rem;
}

@media screen and (max-width: 700px) {

  .insights-card-container {
    padding-bottom: 2rem;
    flex-direction: column;
  }

  .card-img2 {
    width: 70%;
  }

  .insight-card-description {
    width: 100%;
  }

  .insight-card-description>h1 {
    font-size: 1.8rem;
  }

}