.new-industries {
  width: 95%;
  padding-left: 3rem;
  padding-right: 3rem;
}



.new-industries .splide__pagination__page {
  background: gainsboro !important;
  height: 0.6rem !important;
  width: 0.6rem !important;
}

.new-industries .splide__pagination {
  bottom: -0.8rem;

}


@media screen and (min-width:1580px) {
  .new-industries {
    width: 85%;
  }

  .industries-card {
    width: 22rem;
    height: 21rem;
  }
}

@media screen and (min-width:1390px) and (max-width:1580px) {
  .industries-card {
    width: 20rem;
    height: 22rem;
  }
}

@media screen and (min-width:1190px) and (max-width:1390px) {
  .industries-card {
    width: 19rem;
    height: 23rem;
  }
}

@media screen and (min-width:1090px) and (max-width:1190px) {
  .new-industries {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .industries-card {
    width: 18rem;
    height: 23rem;
  }

}

@media screen and (min-width:998px) and (max-width:1090px) {
  .new-industries {
    width: 80%;
  }

  .industries-card {
    width: 19rem;
    height: 22rem;
  }
}

@media screen and (max-width:998px) {
  .new-industries {
    width: 100%;
    border-radius: var(--border-radius);
  }
}

@media screen and (min-width:800px) and (max-width:998px) {

  .industries-card {
    width: 19rem;
    height: 22rem;
  }
}

@media screen and (min-width:685px) and (max-width:800px) {

  .industries-card {
    width: 16rem;
    height: 24rem;
  }
}

@media screen and (min-width:500px) and (max-width:685px) {

  .industries-card {
    width: 90%;
    height: 21rem;
  }
}

@media screen and (max-width:500px) {

  .new-industries {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .industries-card {
    width: 98%;
    height: 23rem;
  }
}

@media screen and (max-width:335px) {

  .new-industries {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .industries-card {
    width: 98%;
    height: 25rem;
  }
}


.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide>div {
  height: 100%;
}

.slide {
  height: 100%;
}

@media screen and (max-width: 685px) {
  .slick-slide>div {
    margin-bottom: 2rem !important;
  }
}