.job-salary-bg {
    background: radial-gradient(213.95% 183.13% at -2.99% 5.02%, var(--view-job-overview-card-bg-color1) 0%, var(--view-job-overview-card-bg-color2) 21.55%, var(--view-job-overview-card-bg-color3) 49.53%, var(--view-job-overview-card-bg-color4) 63.65%, var(--view-job-overview-card-bg-color5) 73.84%, var(--view-job-overview-card-bg-color6) 78.96%, var(--view-job-overview-card-bg-color7) 79.28%, var(--view-job-overview-card-bg-color8) 86.73%, var(--view-job-overview-card-bg-color9) 100%, var(--view-job-overview-card-bg-color10) 100%);
}

.copy-link-btn {
    background: linear-gradient(180deg, rgba(150, 23, 249, 0.80) 0%, rgba(198, 10, 168, 0.80) 100%);
    border: none;
    outline: none;
    color: var(--primary-color);
    transition: all 0.8s;
}

.copy-link-btn:hover {
    background: transparent;
    outline: 0.1rem #632b78 solid;
    border: none;
    transition: all 0.8s;
    color: var(--primary-color) !important;
}