.businesses-container {
  display: flex;
}

.right-section {
  margin-top: 4rem;
  flex-shrink: 1;
  padding: 0 2%;
  flex-grow: 1;
}

.manage-job-card {
  background: var(--user-and-admin-dashboard-cards);
}

.businesses-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 1;
  border-radius: 0.5rem;
  background: var(--user-and-admin-dashboard-cards) !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
  width: 100%;
  height: 16rem;
  padding: 1rem 1rem;
}

.businesses-card-container>h1 {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
}

.businesses-card-container>div {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.businesses-card-container>div>p {
  color: var(--primary-color);
  text-align: left;
  font-family: var(--montserrat);
  font-size: 0.7rem;
  font-weight: 400;
  margin: 0;
}

.businesses-card-container>button {
  color: var(--primary-color);
  text-align: left;
  font-family: var(--montserrat);
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin: auto;
  margin-right: 0;
  display: block;
  margin-bottom: 0;
}

@media screen and (min-width: 270px) and (max-width: 880px) {
  .businesses-container {
    display: flex;
    flex-wrap: wrap;
  }

  .right-section {
    margin-top: 5rem;
  }
}

.paste-button {
  position: relative;
  display: block;
}

.button {
  background-color: var(--user-and-admin-dashboard-cards);
  color: var(--primary-color);
  padding: 3px 20px;
  font-size: 15px;
  border: 2px solid white;
  border-radius: 6px;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  font-size: 13px;
  position: absolute;
  z-index: 1;
  width: 100%;
  background: var(--background-color);
  border: 1px solid white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: var(--primary-color);
  padding: 8px 10px;
  text-decoration: none;
  display: block;
  transition: 0.1s;
}

.dropdown-content a:hover {
  background-color: #333552;
  color: white;
}


.paste-button:hover .dropdown-content {
  display: block;
}