.gradient-text {
  font-family: var(--montserrat);
  font-size: var(--h1-font-size);
  font-weight: 600;
  background: linear-gradient(180deg, var(--admin-create-job-btn1) 0%, var(--admin-create-job-btn2) 100%);
  letter-spacing: 0.1875rem;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.visa-sponsorhip-bg {
  background: var(--visa-sponsorhip-bg), var(--visa-sponsorhip-bg2);
  height: 100%;
}