nav.navbar-container,
.nav-container,
.nav-container>ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--inter);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.navbar-isalogo {
  width: 4rem;
  height: 3rem;
  background: var(--create-account-isalogo);
  background-repeat: no-repeat;
  background-size: contain;
  object-position: center;
}

.active-link {
  opacity: 1 !important;
}

.insigth-dropdown {
  margin-left: -18rem;
}

.show-arrow {
  display: none;
  transition: transform ease-in-out 1000ms;
}

.read-more-btn:hover {
  border-bottom: solid 1.5px #E578FF;
}

.read-more-btn:hover .show-arrow {
  display: block;
  transition: all ease-in-out 3s;
}