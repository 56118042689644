.search_icon {
  height: 1em;
  padding: 0 0.7em 0 0.8em;
  fill: #FFFFFFCC;
}

.search-container {
  position: relative;
  box-sizing: border-box;
  width: fit-content;
}

.mainbox {
  box-sizing: border-box;
  position: relative;
  width: 500px;
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: var(--user-and-admin-dashboard-cards);
  transition: all 0.3s ease;
}

.checkbox:focus {
  border: none;
  outline: none;
}

.checkbox:checked {
  right: 10px;
}

.checkbox:checked~.mainbox {
  width: 50px;
}

.checkbox:checked~.mainbox .search_input {
  width: 0;
  height: 0px;
  opacity: 0;
}

.checkbox:checked~.mainbox .iconContainer {
  padding-right: 20px;
}

.checkbox {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 17px;
  top: 10px;
  z-index: 9;
  cursor: pointer;
  appearance: none;
}

.search_input {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: var(--side-bar-bg);
  border: none;
  outline: none;
  padding-bottom: 4px;
  padding-left: 18px;
  font-size: 1em;
  border-radius: 10px;
  color: var(--primary-color);
  transition: all 0.3s ease;
}

.search_input::placeholder {
  color: rgba(255, 255, 255, 0.776);
}

.iconContainer {
  box-sizing: border-box;
  padding-top: 5px;
  width: fit-content;
  transition: all 0.3s ease;
}

.search_icon {
  box-sizing: border-box;
  fill: var(--white-blue-icons);
  font-size: 1.3em;
}

/* ------ search input */
@media screen and (max-width: 700px) {
  .mainbox {
    width: 90%;
  }
}