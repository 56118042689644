.labour-trends-container {
  background: var(--labour-trends);
  z-index: 20000;
}

.labour-trends-container>h1 {
  text-align: center;
  font-family: var(--montserrat);
  padding: 4rem 0;
  font-weight: 500;
  position: relative;
}

.labour-trends-description>h1 {
  font-family: var(--montserrat);
  font-size: var(--h1-font-size);
  font-weight: 500;
  line-height: 2rem;
  /* text-align: center; */
  margin: 2rem auto;
  margin-bottom: 0;
}

.labour-trends-description>p {
  color: rgba(255, 255, 255, 0.70);
  text-align: left;
  font-family: var(--montserrat);
  font-size: var(--paragraph-font-size);
  font-weight: 400;
  line-height: var(--line-height);
  margin: 1.5rem auto;
}

.labour-trends-description>button {
  font-family: var(--montserrat);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: normal;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 1rem auto;
  margin-top: 4rem;
  display: block;
}

@media screen and (max-width: 787px) {
  .labour-trends-description {
    width: 100%;
  }
}