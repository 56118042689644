.job-search-side-card {
  background: radial-gradient(184.86% 158.88% at 15.32% 21.04%, var(--industries-bg-color1) 0%, var(--industries-bg-color2) 67.53%, var(--industries-bg-color3) 77.08%, var(--industries-bg-color4) 79.28%, var(--industries-bg-color5) 80.71%, var(--industries-bg-color6) 86.73%, var(--industries-bg-color7) 100%, var(--industries-bg-color8) 100%);
  border-radius: var(--border-radius);
}

#sortJobs,
#sortJobs option {
  background: var(--inputs-bg) !important;
  color: var(--primary-color)
}


.hide-div {
  display: none;
}

.show-div {
  display: block;
  transition: transform 0.3s ease-in-out;
}