@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: var(--background-color);
  font-family: var(--montserrat), sans-serif;
  color: var(--primary-color);
}

@layer utilities {
  .border-gradient {
    border-image: linear-gradient(180deg,
        rgba(150, 23, 249, 0.8) 0%,
        rgba(198, 10, 168, 0.8) 100%);
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'TT Hoves';
}

a {
  text-decoration: none;
  cursor: pointer;
}

input::placeholder {
  color: var(--primary-color) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--inputs-bg) inset !important;
  color: var(--primary-color) !important;
  -webkit-text-fill-color: var(--primary-color) !important;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  border: 1px solid white;
  background-color: #111;
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-moon {
  color: #f1c40f;
}

.fa-sun {
  color: #f39c12;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked+.checkbox-label .ball {
  transform: translateX(24px);
}

.full-page-loader {
  background: var(--full-page-loader);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 11rem;
  height: 11rem;
}

.privacy-policy-img {
  background: var(--create-account-isalogo);
  background-repeat: no-repeat;
  background-size: contain;
}

.shadow-gradient {
  box-shadow: 0 0 4px 3px rgba(125, 76, 208, 0.6),
    0 0 4px 3px rgba(249, 140, 196, 0.6);
}

.shadow-gradient-hover:hover {
  box-shadow: 0 0 4px 3px rgba(125, 76, 208, 0.6),
    0 0 4px 3px rgba(249, 140, 196, 0.6);
}

.cld-video-player {
  background: var(--background-color) !important;
}

.arrow-effect-btn,
.back-arrow-effect-btn {
  text-align: center;
  padding: 12px 26px;
  transition: all 0.5s;
  cursor: pointer;
}

.arrow-effect-btn,
.back-arrow-effect-btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.arrow-effect-btn:after,
.back-arrow-effect-btn:after {
  content: '→';
  position: absolute;
  opacity: 0;
  right: -20px;
  transition: 0.5s;
}

.back-arrow-effect-btn:after {
  content: '←';
  right: 100%;
  left: -20px;
}

.arrow-effect-btn:hover {
  padding-right: 34px;
  padding-left: 18px;
}

.back-arrow-effect-btn:hover {
  padding-left: 34px;
  padding-right: 18px;
}

.arrow-effect-btn:hover:after,
.back-arrow-effect-btn:hover:after {
  opacity: 1;
  right: 10px;
}

.back-arrow-effect-btn:hover:after {
  left: -30px;
}

.SA-button {
  transform-style: preserve-3d;
  animation: rot 3s infinite ease;
  transition: 1.5s;
}

@keyframes rot {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* -------------------------------YYYYYYYYYYYYY------------------- */
.fill-on-hover-btn {
  display: inline-block;
  padding: 0.9rem 1.8rem;
  color: white;
  border: 3px solid white;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  z-index: 1;
}

.fill-on-hover-btn::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  transform: translateX(-100%);
  transition: all 0.3s;
  z-index: -1;
}

.fill-on-hover-btn:hover::before {
  transform: translateX(0);
}

.findHeading {
  color: var(--primary-color);
  margin-bottom: 5rem;
  margin-top: 3rem;
  width: 95%;
  text-align: center;
}

.sidebar-bg {
  background: var(--side-bar-bg) !important;
  backdrop-filter: blur(25px);
}

.jobs-card-bg {
  background: var(--user-and-admin-dashboard-cards) !important;
}

/* react-tostify library */
@media only screen and (max-width: 600px) {
  .Toastify__toast {
    width: 80vw !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}