.footer {
  box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
  font-size: 14px;
  padding: 32px 5%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: var(--footer-bg);
  z-index: 100000;
  max-width: var(--max-width-all-devices);
  margin: auto;
}

.footer div {
  padding: 0;
}

.logo {
  background-image: var(--create-account-isalogo);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 70%;
  height: 70%;
  margin-top: 0;
}

.social-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.social-links img {
  width: 20px;
  height: 20px;
  margin-left: 0;
}

.footer p {
  color: var(--primary-color);
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem;
  margin: 0 !important;
  padding: 0 !important;
}

.footer h6 {
  color: var(--primary-color);
  margin-top: 1.3rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1.2rem;
}

@media screen and (max-width: 775px) {
  .footer .logo {
    width: 80%;
    height: 80%;
  }
}