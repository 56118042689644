/* These styling is for react-city-state-dropdown package */

.rcsd.rcsd-dropdown {
    width: 100% !important;
    background: var(--inputs-bg) !important;
    border-radius: var(--border-radius);
    outline: none !important;
    padding-left: 0.5rem !important;
}

.rcsd.rcsd-dropdown .rcsd-input {
    border: none !important;
}

.rcsd.rcsd-dropdown .rcsd-input i.icon {
    background-color: transparent !important;
    padding: 0.3rem 0.4rem 0 0 !important;
}

.rcsd.rcsd-dropdown .rcsd-input i.clear::before {
    content: "" !important;
}

.rcsd.rcsd-dropdown .menu,
.rcsd.rcsd-dropdown .menu .items .item {
    background: var(--inputs-bg) !important;
}

.rcsd.rcsd-dropdown .menu .items .item:hover {
    opacity: 0.7;
}

.rcsd.rcsd-dropdown .rcsd-input input {
    padding-right: 0 !important;
}

.rcsd.rcsd-dropdown .rcsd-input .wrapped-input:has(.flag) input {
    width: calc(100% - 63px) !important;
}


.text-color {
    background: var(--linear, linear-gradient(180deg, rgba(150, 23, 249, 0.80) 0%, rgba(198, 10, 168, 0.80) 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.3rem;
    font-weight: 600;
}

.select-opt-bg>option {
    background: var(--background-color);
}

.PhoneInputInput {
    outline: none;
    border: none;
    background: transparent;
    color: var(--heading-color-1) !important;
}

.PhoneInputInput::placeholder {
    color: var(--heading-color-1) !important;
}

@media (max-width: 719px) {
    .min-h-screen {
        min-height: 130vh;
    }
}