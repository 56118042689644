.create-job-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.create-job-container>main {
  padding: 1rem 0;
  margin: 4rem 0;
}

.create-job-container>main>h1 {
  font-size: 1.5625rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.create-job-input-conatiner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.create-job-input-conatiner>div {
  min-width: 32%;
  flex-grow: 1;
  margin-bottom: 1.5rem;
}

.create-job-input-conatiner>div>label {
  font-size: 1.07rem;
  font-weight: 400;
  letter-spacing: 0.05625rem;
  margin-bottom: 0.5rem;
}

.create-job-input-conatiner>div>input,
.create-job-input-conatiner>div>select,
#job-type {
  display: block;
  width: 100%;
  border-radius: 0.625rem;
  background: linear-gradient(180deg, rgba(64, 70, 101, 0.80) 0.03%, rgba(64, 70, 101, 0.36) 108.7%, rgba(64, 70, 101, 0.00) 129.92%);
  backdrop-filter: blur(25px);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.04375rem;
  padding: 0.8rem 1rem;
  outline: none;
}

.create-job-container>main>h3 {
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.05625rem;
  margin-top: 2rem;
}

.job-description {
  background: var(--inputs-bg);
  backdrop-filter: blur(25px);
  padding: 2rem;
  color: var(--primary-color) !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 168%;
  letter-spacing: 0.04375rem;
  margin-bottom: 3rem;
  resize: none;
  width: 100%;
  outline: none;
  height: 10rem;
  max-height: fit-content;
  overflow: auto;
}

#job-type {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

#job-type>option {
  background-color: #404665cc !important;
}

.create-job-container>main>button {
  font-family: var(--montserrat);
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.05625rem;
  width: 98%;
  display: block;
  max-width: 15rem;
  margin-right: 0 !important;
  margin-left: auto !important;
}

ul {
  list-style-type: disc;
}