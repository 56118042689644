.tech-card-bg {
  background: var(--connect-1on1-img-bg);
}

.tech-we-support-bg {
  background: var(--tech-we-support-bg);
}

/* -----------These styling is for react-splide library----------- */
.splide__arrow--prev {
  left: 0;
  margin-left: -3.3rem;
}

.splide__arrow--next {
  right: 0;
  margin-right: -3.3rem;
}

.splide__arrow {
  background: var(--primary-color) !important;
  opacity: 1 !important;
}

.splide__pagination {
  margin-bottom: -1.5rem !important;
}

.splide__arrow svg {
  fill: var(--appleid-btn-text) !important;
}