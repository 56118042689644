.company-stats-bg1 {
  background: url("../assets/Images/Stars.png");
  background-size: contain;
  background-position: top;
  /* background-repeat: no-repeat; */
}


@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

.gradient-border2 {
  --border-width: 1.5px;
  position: relative;
  border-radius: 1.5px;
  /* padding: 1rem; */

  &::after {
    position: absolute;
    content: "";
    z-index: -1;
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(60deg,
        #817CC9,
        #817CC9,
        #817cc900,
        #817CC9,
        #817cc900,
        #817CC9,
        #817cc900,
        #817CC9);
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * 0.5rem);
    animation: moveGradient 6s infinite;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}