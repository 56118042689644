@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  /* ----- Colors ----- */
  --card-bg-color1: #0a0118;
  --card-bg-color2: #272442;
  --primary-color: #fff;
  --secondary-color: rgba(255, 255, 255, 0.7);
  --background-color: #11071f;
  --navbar-dropdown-bg: #11071f;
  --navbar-bg-color: #11071f;
  --black-bg-btn: #09041d;
  --cards-background: linear-gradient(
    180deg,
    #404665 0.03%,
    rgba(64, 70, 101, 0.45) 108.7%,
    rgba(64, 70, 101, 0) 129.92%
  );
  --footer-IconBg: #5c6ad2;
  --inputs-bg: #40466530;
  --bg-Image-URL: radial-gradient(
      50% 50% at 50% 50%,
      #763cac2a 0%,
      rgba(50, 15, 133, 0) 100%
    ),
    url('../../assets/Images/Stars2.webp');
  --create-account-isalogo: url('../../assets/Images/ISA_Logo.png');
  --navbar-dropdown-img: url('../../assets/Images/dropdown-bg-img2.webp');
  --active-ractangle-img: url('../../Images/Rectangle 2.svg');
  --hero-bg-img: url('../../assets/Images/newStars.png'),
    radial-gradient(50% 50% at 50% 50%, #ffffff00 0%, rgba(0, 0, 0, 0) 100%),
    radial-gradient(50% 50% at 50% 50%, #5d3abe41 0%, rgba(0, 0, 0, 0) 100%);
  --full-page-loader: url('../../assets/Images/loader3.gif');
  --footer-bg: radial-gradient(
    50% 50% at 50% 50%,
    #020b24 0%,
    rgba(50, 15, 133, 0) 100%
  );
  --card-Bg: rgb(255 255 255 / 0.05);
  --heading-color-1: #c2c2c2;
  --insights2-bg: radial-gradient(
    50% 40% at 50% 65%,
    #3325b15b 0%,
    #00000000 100%
  );
  --social-bg: #ffffff1c;
  --user-dashboard-gray-text: rgb(189 190 186);
  --user-dashboard-cards: #40476b;
  --toast-bg: #160939;

  --side-bar-bg: linear-gradient(
    180deg,
    rgba(64, 70, 101, 0.8) 0.03%,
    rgba(64, 70, 101, 0.36) 108.7%,
    rgba(64, 70, 101, 0) 129.92%
  );
  --user-and-admin-dashboard-cards: linear-gradient(
    180deg,
    rgba(64, 70, 101, 0.8) 0.03%,
    rgba(64, 70, 101, 0.36) 108.7%,
    rgba(64, 70, 101, 0) 129.92%
  );
  --user-and-admin-dashboard-body-bg: #11071f;
  --user-and-admin-dashboard-btns: #5c6ad2;
  --connect-1on1-cards-bg: linear-gradient(
    180deg,
    rgba(64, 70, 101, 0.55) 0.03%,
    rgba(64, 70, 101, 0.45) 108.7%,
    rgba(64, 70, 101, 0) 129.92%
  );
  --connect-1on1-bg: radial-gradient(
    50% 50% at 50% 30%,
    #5c4eda50 0%,
    rgba(50, 15, 133, 0) 100%
  );
  --create-account-bg: radial-gradient(
    50% 50% at 50% 50%,
    #5c4eda50 0%,
    rgba(50, 15, 133, 0) 100%
  );
  --connect-1on1-img-bg: radial-gradient(
    50% 50% at 50% 50%,
    #5c4eda50 0%,
    rgba(0, 0, 0, 0) 100%
  );
  --connect-1on1-section-bg: linear-gradient(
    180deg,
    rgba(64, 70, 101, 0.41) 0.03%,
    rgba(64, 70, 101, 0.45) 108.7%,
    rgba(64, 70, 101, 0) 129.92%
  );
  --user-sidebar-hover: #404665;
  --appleid-btn-text: black;
  --insight-heading: linear-gradient(
    180deg,
    #fff 0%,
    rgba(255, 255, 255, 0) 141.78%
  );
  --apply-job-card-bg: radial-gradient(
    50% 50% at 50% 50%,
    #5c4eda5a 0%,
    #00000000 100%
  );

  --industries-bg-color1: #693b93;
  --industries-bg-color2: rgba(108, 137, 204, 0.54);
  --industries-bg-color3: rgba(110, 191, 244, 0.22);
  --industries-bg-color4: rgba(108, 134, 202, 0.56);
  --industries-bg-color5: rgba(106, 80, 162, 0.88);
  --industries-bg-color6: rgba(90, 139, 207, 0.3);
  --industries-bg-color7: rgba(70, 144, 212, 0);
  --industries-bg-color8: rgba(103, 183, 239, 0.19);

  --montserrat: 'TT Hoves';
  --inter: 'TT Hoves';
  --roboto: 'TT Hoves';
  --lexend-deca: 'TT Hoves';
  --poppins: 'TT Hoves';

  --white-blue-icons: #fff;
  --admin-create-job-btn1: rgba(150, 23, 249, 0.8);
  --admin-create-job-btn2: rgba(198, 10, 168, 0.8);

  --view-job-overview-card-bg-color1: #693b93;
  --view-job-overview-card-bg-color2: rgba(106, 80, 162, 0.88);
  --view-job-overview-card-bg-color3: rgba(106, 99, 176, 0.77);
  --view-job-overview-card-bg-color4: rgba(107, 115, 188, 0.67);
  --view-job-overview-card-bg-color5: rgba(106, 102, 179, 0.75);
  --view-job-overview-card-bg-color6: rgba(108, 137, 204, 0.54);
  --view-job-overview-card-bg-color7: rgba(108, 134, 202, 0.56);
  --view-job-overview-card-bg-color8: rgba(90, 139, 207, 0.3);
  --view-job-overview-card-bg-color9: rgba(70, 144, 212, 0);
  --view-job-overview-card-bg-color10: rgba(103, 183, 239, 0.19);

  /* ----- Input, cards, btn etc border-radius */
  --border-radius: 1rem;
  --line-height: 1.5rem;

  /* ------- Paragraph, h1,h2 fonts size */
  --h1-font-size: 2rem;

  /* --------input text ----- */
  --max-width-all-devices: 1750px;
  --visa-sponsorhip-bg: radial-gradient(
    30% 30% at 50% 25%,
    #5c4eda50 0%,
    #00000000 90%
  );
  --visa-sponsorhip-bg2: radial-gradient(
    30% 30% at 50% 78%,
    #5c4eda48 0%,
    #00000000 90%
  );
  --contact-isa-bg: radial-gradient(
    50% 50% at 50% 30%,
    #5c4eda2b 0%,
    #00000000 70%
  );
  --tech-we-support-bg: radial-gradient(
    50% 50% at 50% 50%,
    #5c4eda5a 0%,
    #00000000 100%
  );
  --mission_vision_story-bg: radial-gradient(
    50% 50% at 50% 30%,
    #5c4eda67 0%,
    #00000000 70%
  );

  --home-contact-us-input-borders: #ffffff2f;
  --industires-circle-bg1: #683b9389;
  --industires-circle-bg4: #3a274e6f;
  --build-business-bg1: #2d0250;
  --build-business-bg2: #170128;
  --create-account-card-bg: #1806285a;
  --login-input-bg: #40466530;
  --labour-trends: radial-gradient(
    50% 50% at 50% 50%,
    #5c4eda50 0%,
    rgba(50, 15, 133, 0) 100%
  );
  --leading-edge-bg: radial-gradient(
    50% 50% at 50% 50%,
    #5c4eda5b 0%,
    rgba(0, 0, 0, 0) 100%
  );
  --home-hero-animation-text: white;
  --home-hero-animation-bg: #9617f9;
  --partner-border-bg: #9617f9;
  --ISACenterOfExcellenceCard-bg: #ffffff12;
  --ISACenterOfExcellenceCard-hover-bg: #2b114f;
}

body.light-mode {
  --ISACenterOfExcellenceCard-bg: #9717f9a5;
  --ISACenterOfExcellenceCard-hover-bg: #9717f9;
  --partner-border-bg: #9617f9;
  --home-hero-animation-bg: white;
  --home-hero-animation-text: rgb(150, 23, 249);
  --contact-isa-bg: white;
  --leading-edge-bg: white;
  --labour-trends: white;
  --tech-we-support-bg: #fff;
  --mission_vision_story-bg: #fff;
  --login-input-bg: white;
  --build-business-bg1: #e7e7e7;
  --build-business-bg2: #e7e7e7;
  --industires-circle-bg1: black;
  --industires-circle-bg4: white;
  --card-bg-color1: white;
  --card-bg-color2: white;
  --black-bg-btn: white;
  --insights2-bg: white;
  --home-contact-us-input-borders: #11071f52;
  --bg-contactUsImg: #fff;
  --create-account-bg: #ffffff00;
  --create-account-card-bg: #fff;
  --visa-sponsorhip-bg: white;
  --visa-sponsorhip-bg2: white;
  --bg-contactUsImg2: radial-gradient(
    70% 70% at 50% 50%,
    #661fff68 0%,
    #661fff47 50%,
    #661fff31 100%
  );
  --find-work-light-theme-bg: radial-gradient(
    70% 70% at 50% 50%,
    #661fff68 0%,
    #661fff2d 50%,
    #661fff00 100%
  );
  --visa-sponsorship-solutions-container-bg: radial-gradient(
    60% 100% at 50% 60%,
    #661fff68 0%,
    #661fff2d 50%,
    #661fff00 100%
  );

  --hero-bg-img: rgba(255, 255, 255, 0);
  --bg-Image-URL: #fff;
  --what-we-offer-bg1: radial-gradient(
    70% 50% at 70% 45%,
    #b60ac63d 0%,
    #b60ac63d 40%,
    #fff 100%
  );
  --what-we-offer-bg2: radial-gradient(
    70% 60% at 20% 50%,
    #b60ac646 0%,
    #b60ac630 40%,
    #fff 100%
  );
  --create-account-isalogo: url('../../assets/Images/ISA_Logo_black.png');
  --navbar-dropdown-img: url('../../assets/Images/dropdown-bg-img.webp');
  --active-ractangle-img: url('../../assets/Images/Rectangle 2.svg');
  --full-page-loader: url('../../assets/Images/ISA_Logo_black.gif');
  --footer-IconBg: #000;
  --primary-color: #11071f;
  --background-color: #fff;
  --navbar-dropdown-bg: #e7e7e7;
  --navbar-bg-color: white;
  --secondary-color: var(--primary-color);

  --white-blue-icons: #000;
  --appleid-btn-text: white;
  --user-sidebar-hover: #75777f9c;
  --insight-heading: #000;
  --inputs-bg: #75777f5b;
  --footer-bg: linear-gradient(180deg, #f1f1f1 0%, #e7e7e7 100%);
  --card-Bg: #e7e7e7;
  --admin-create-job-btn1: #11071f;
  --admin-create-job-btn2: #11071f;

  --industries-bg-color1: #fff;
  --industries-bg-color2: #fff;
  --industries-bg-color3: #fff;
  --industries-bg-color4: #fff;
  --industries-bg-color5: #fff;
  --industries-bg-color6: #fff;
  --industries-bg-color7: #fff;
  --industries-bg-color8: #fff;

  --heading-color-1: #000;
  --cards-background: #9717f931;
  --connect-1on1-cards-bg: #9717f931;
  --side-bar-bg: #ddd;
  --user-and-admin-dashboard-cards: #dddddd;
  --user-and-admin-dashboard-body-bg: white;
  --user-and-admin-dashboard-btns: #11071f;
  --user-dashboard-cards: #e7e7e7;
  --toast-bg: #dddddd;
  --apply-job-card-bg: #9717f931;
  --connect-1on1-bg: white;
  --connect-1on1-img-bg: transparent;
  --connect-1on1-section-bg: white;
  --user-dashboard-gray-text: #000;
  --view-job-overview-card-bg-color1: #e7e7e7;
  --view-job-overview-card-bg-color2: #e7e7e7;
  --view-job-overview-card-bg-color3: #e7e7e7;
  --view-job-overview-card-bg-color4: #e7e7e7;
  --view-job-overview-card-bg-color5: #e7e7e7;
  --view-job-overview-card-bg-color6: #e7e7e7;
  --view-job-overview-card-bg-color7: #e7e7e7;
  --view-job-overview-card-bg-color8: #e7e7e7;
  --view-job-overview-card-bg-color9: #e7e7e7;
  --view-job-overview-card-bg-color10: #e7e7e7;
}
