.blog-upload-card {
  background: var(--user-and-admin-dashboard-cards);
}

/* react-quill package style */
.ql-editor {
  white-space: wrap;
  word-wrap: break-word;
}

.quill>.ql-container>.ql-editor.ql-blank::before {
  color: var(--primary-color);
}

.ql-toolbar .ql-stroke {
  fill: none;
  stroke: var(--primary-color);
}

.ql-toolbar .ql-fill {
  fill: var(--primary-color);
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: var(--primary-color);
}

button:hover .ql-stroke,
.ql-picker-label:hover .ql-stroke {
  fill: none;
  stroke: red !important;
}

.ql-active .ql-stroke {
  fill: none;
  stroke: red !important;
}

button:hover .ql-fill,
.ql-picker-label:hover .ql-fill {
  fill: red !important;
  stroke: none;
}

.ql-active .ql-fill {
  fill: red !important;
  stroke: none;
}