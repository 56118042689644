.insight-leading-edge-container {
  background: var(--leading-edge-bg)
}

.insight-leading-edge-container>h1 {
  text-align: center;
  font-family: var(--montserrat);
  font-weight: 500;
  padding: 4rem 1rem;
}

.leading-edge-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  padding: 0 5%;
  padding-bottom: 2rem;
}

.leading-edge-card-img {
  width: 40%;
  height: 100%;
}

@media screen and (max-width: 720px) {

  .insight-leading-edge-container>h1 {
    padding-top: 4rem;
  }

  .leading-edge-card-img {
    width: 70%;
  }

  .leading-edge-card {
    flex-direction: column;
    gap: 4rem;
  }

}