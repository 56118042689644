.blog-details h1 {
  font-size: 2em;
  margin-top: 15px;
  font-weight: bold;
  color: var(--primary-color) !important;
}

.blog-details h2 {
  font-size: 1.5em;
  margin-top: 15px;
  color: var(--primary-color) !important;
  font-weight: bold;
}

.blog-details h3 {
  font-size: 1.3em;
  margin-top: 15px;
  color: var(--primary-color) !important;
  font-weight: bold;
}

.blog-details h4 {
  font-size: 1.1em;
  margin-top: 15px;
  color: var(--primary-color) !important;
  font-weight: bold;
}

.blog-details ol {
  list-style-type: decimal;
  margin-left: 20px;
  color: var(--primary-color) !important;
  margin-top: 5px;
}

.blog-details li {
  margin-bottom: 5px;
  color: var(--primary-color) !important;
  margin-left: 20px;
}

.blog-details p {
  color: var(--primary-color) !important;
  margin-top: 3px;
  line-height: 1.4;
  font-size: 17px;
}