.job-container {
  margin-bottom: 4rem;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.40);
  padding-bottom: 0.4rem;
}

.job-description {
  color: rgba(255, 255, 255, 0.70);
  text-align: left;
  font-family: var(--montserrat);
  font-size: var(--paragraph-font-size);
  font-weight: 400;
  line-height: var(--line-height);
  margin-bottom: 0.8rem !important;
  margin-top: 0.8rem !important;
}

@media screen and (max-width:780px) {
  .job-description {
    font-size: 1rem;
  }

}