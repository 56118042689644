.super-admin-container {
  background: var(--user-and-admin-dashboard-body-bg);
  display: flex;
}

.super-admin-right-sidebar {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 7rem 0;
  padding: 0 2%;
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "feature1"
    "feature2"
    "feature3"
    "feature4"
    "top-10-job"
    "registered-positions"
    "total-count"
}

.super-admin-right-sidebar> :nth-child(1) {
  grid-area: feature1;

}

.super-admin-right-sidebar> :nth-child(2) {
  grid-area: feature2;

}

.super-admin-right-sidebar> :nth-child(2) {
  grid-area: feature2;

}

.super-admin-right-sidebar> :nth-child(3) {
  grid-area: feature3;

}

.super-admin-right-sidebar> :nth-child(4) {
  grid-area: feature4;
}

.super-admin-right-sidebar> :nth-child(5) {
  grid-area: top-10-job;
}

.super-admin-right-sidebar> :nth-child(6) {
  grid-area: registered-positions;
}

.super-admin-right-sidebar> :nth-child(7) {
  grid-area: total-count;
}

.total-applicants {
  border-radius: 0.5rem;
  background: var(--user-and-admin-dashboard-cards);
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.title-and-number {
  text-align: right;
}

.title-and-number>h1 {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 0.3rem;
}

.title-and-number>p {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 0.875rem;
  font-weight: 400;
  white-space: pre;
}

.icon-container {
  width: 2rem;
  height: 2rem;
  background-color: var(--primary-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container>img {
  width: 1rem;
  height: 1rem;
  object-fit: contain;
  object-position: center;
}

table {
  color: var(--primary-color);
  width: 100%;
}


.top-10-job-applications {
  background: var(--user-and-admin-dashboard-cards);
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.14));
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-x: auto;
  white-space: pre;
}

.table-heading-plus-btn {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.see-all-btn {
  color: rgba(255, 255, 255, 0.66);
  font-family: var(--lato);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 3rem;
  margin-right: 0;
}

.table-heading-plus-btn>h1 {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
  letter-spacing: 0.1125rem;
}

.top-10-job-applications table th {
  font-family: var(--montserrat);
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0.07813rem;
  padding-right: 1rem;
}

.top-10-job-applications table td {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.05625rem;
  padding-bottom: 1.3rem;
  padding-right: 0.7rem;
}

.top-10-job-applications table td:first-child {
  color: rgba(216, 216, 216, 0.67);
  font-family: var(--montserrat);
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.05625rem;
}

.registered-vacant-position {
  border-radius: 0.5rem;
  background: var(--user-and-admin-dashboard-cards);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.registered-first-div {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1rem;
  padding-bottom: 0;
  margin: 0;
}

.last-seven-days {
  border: none;
  background: transparent;
  cursor: pointer;
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 0.875rem;
  font-weight: 400;
  margin-left: 1rem;
}

.registered-vacant-position>h1 {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 2rem;
  font-weight: 600;
  margin-left: 2rem;
  margin-top: 1rem;
}

.position-filled-container {
  width: 10rem;
  height: 9rem;
  position: relative;
  margin: 0 auto;
  padding: 0;
}

.position-filled-container>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0;
  padding: 0;
  fill: linear-gradient(233deg, #E95456 7.63%, #8C64D2 90.57%);
}

.percentage-filled-container {
  position: absolute;
  top: 38%;
  left: 17%;
  text-align: center;
}

.percentage-filled-container>p {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 0.82725rem;
  font-weight: 400;
}

.percentage-filled-container>h1 {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 1.5rem;
  font-weight: 600;
}

.total-count-container {
  border-radius: 0.5rem;
  background: var(--user-and-admin-dashboard-cards);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
  padding: 1rem;
  ;
}

.total-count-container>h3 {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding-bottom: 1rem;
}

.total-count-stat-contianer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.count-main-contianer {
  width: 50%;
}

.count-contianer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  padding-left: 0;
}

.count-contianer>img {
  width: 0.75rem;
  height: 0.75rem;
}

.count-contianer .p {
  margin-right: auto;
  padding-left: 0.4rem;
}

.count-contianer>p {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.0625rem;
}

.circle-chart>img {
  width: 9rem;
  height: 9rem;
  object-fit: contain;
  object-position: center;
}

@media screen and (min-width:270px) and (max-width:880px) {

  .top-10-job-applications * {
    font-size: 0.9rem !important;
  }

  .registered-vacant-position h1 {
    font-size: 1.2rem;
  }

  .position-filled-container {
    width: 9rem;
  }
}

@media screen and (min-width: 1024px) {
  .super-admin-right-sidebar {
    grid-template-areas:
      "feature1 feature2 feature3 feature4"
      "top-10-job top-10-job registered-positions registered-positions"
      "top-10-job top-10-job total-count total-count";
  }
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .super-admin-right-sidebar {
    grid-template-areas:
      "feature1 feature2"
      "feature3 feature4"
      "top-10-job top-10-job"
      "registered-positions registered-positions"
      "total-count total-count";
  }
}

@media screen and (max-width: 500px) {
  .count-main-contianer {
    width: 16rem;
  }

  .count-contianer>p {
    margin-right: 1rem;
    padding-left: 1rem;
  }
}



/* bell icon css */
/*------ Settings ------*/
.container {
  --color: #a5a5b0;
  --size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: var(--size);
  user-select: none;
  fill: var(--color);
}

.container .bell-regular {
  position: absolute;
  animation: keyframes-fill .5s;
}

.container .bell-solid {
  position: absolute;
  display: none;
  animation: keyframes-fill .5s;
}

/* ------ On check event ------ */
.container input:checked~.bell-regular {
  display: none;
}

.container input:checked~.bell-solid {
  display: block;
}

/* ------ Hide the default checkbox ------ */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* ------ Animation ------ */
@keyframes keyframes-fill {
  0% {
    opacity: 0;
  }

  25% {
    transform: rotate(25deg);
  }

  50% {
    transform: rotate(-20deg) scale(1.2);
  }

  75% {
    transform: rotate(15deg);
  }
}

/* end bell icon css */




/* bell icon drop down */

.card {
  max-width: 320px;
  border-width: 1px;
  border-color: rgba(219, 234, 254, 1);
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
  padding: 1rem;
}

.header {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: rgba(96, 165, 250, 1);
  padding: 0.5rem;
  color: rgba(255, 255, 255, 1);
}

.closeButton {
  border: none;
  margin-left: 23%;
  border-radius: 0.6rem;
  font-size: 1rem;
  height: 2rem;
  width: 2rem;
  display: grid;
  place-items: center;
  transition: background-color ease 0.25s;
}

.closeButton:hover {
  background-color: rgba(230, 25, 25, 1);
  color: white;
}

.icon svg {
  height: 1rem;
  width: 1rem;
}

.alert {
  font-weight: 600;
  color: rgba(107, 114, 128, 1);
}

.message {
  margin-top: 1rem;
  color: rgba(107, 114, 128, 1);
}

.actions {
  margin-top: 1.5rem;
}

.actions a {
  text-decoration: none;
}

.mark-as-read,
.read {
  display: inline-block;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.75rem 1.25rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}

.read {
  background-color: rgba(59, 130, 246, 1);
  color: rgba(255, 255, 255, 1);
}

.read:hover {
  background-color: rgba(17, 104, 245, 1);
}

.mark-as-read {
  margin-top: 0.5rem;
  background-color: rgba(249, 250, 251, 1);
  color: rgba(107, 114, 128, 1);
  transition: all 0.15s ease;
}

.mark-as-read:hover {
  background-color: rgb(230, 231, 233);
}

/* end drop down bell icon */