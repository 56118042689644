.active-rectangle {
  background-image: var(--active-ractangle-img);
  background-repeat: no-repeat;
  background-size: contain;
}

.super-admin-left-sidebar {
  background: var(--side-bar-bg);
  padding-top: 2.3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.8rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  width: 15rem;
  min-height: 45rem;
  white-space: pre;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  flex-shrink: 0;
  overflow-y: auto;
}

.search-bell-icon-container {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.search-bell-icon-container>button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.search-bell-icon-container img {
  width: 1.65444rem;
  height: 1.65444rem;
  margin-right: 1rem;
}

.super-admin-left-sidebar>img {
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  padding-bottom: 0;
}

.admin-name {
  margin: 0 auto;
  padding: 0;
  color: var(--primary-color);
}

.super-admin-left-sidebar>a {
  position: relative;
}

.super-admin-left-sidebar>a>button,
.super-admin-left-sidebar>div>a>button {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.25rem;
  border: none;
  background: transparent;
  display: flex;
  align-items: baseline;
  text-align: start;
  padding-left: 2rem;
}

.create-job-btn {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
  border-radius: 0.89175rem;
  background: linear-gradient(180deg, var(--admin-create-job-btn1) 0%, var(--admin-create-job-btn2) 100%) !important;
  padding: 1rem 2rem;
  margin: 2rem 0 1rem auto;
  margin-top: 0;
  color: white !important;
}

.super-admin-left-sidebar>a>button>img {
  width: 1rem;
  height: 1rem;
  margin-right: 0.7rem;
}

@media screen and (min-width: 270px) and (max-width: 880px) {
  .super-admin-left-sidebar {
    margin: 1rem;
    margin-top: 0;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

.super-admin-left-sidebar.show {
  transform: translateX(0);
}

.hamburger-menu {
  display: none;
}

@media screen and (min-width:270px) and (max-width:880px) {
  .super-admin-left-sidebar {
    transform: translateX(-110%);
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: fit-content;
    background: var(--user-dashboard-cards);
    z-index: 1000;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: 0;
    scroll-behavior: smooth;
    overflow-y: auto;
  }

  .hamburger-menu {
    display: block;
    position: absolute;
    top: 10px;
    left: 1rem;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1001;
    color: white;
  }

  .search-bell-icon-container {
    display: none;
  }
}