.drop-down-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-left: auto;
  margin-right: 0;

}

.navbar-dropdown-img {
  background-image: var(--navbar-dropdown-img);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.drop-down-cards-container {
  position: absolute;
  background: var(--navbar-dropdown-bg);
  box-shadow: 0px 3.288px 3.288px 4.932px rgba(194, 194, 194, 0.16);
  border-radius: var(--border-radius);
  padding: 1rem;
  width: fit-content;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 2;
  left: 5%;
}

.drop-down-opts-btns {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--primary-color);
  font-family: var(--inter);
  font-size: var(--paragraph-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-grow: 1;
}

.drop-down-opts-btns>a {
  display: flex;
  align-items: start;
  gap: 1rem;
  white-space: pre;
  transition: all 0.3s;
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: var(--border-radius);
  color: var(--primary-color);
}

.drop-down-opts-btns>a:hover {
  background: #40476BC2;
}

.drop-down-opts-btns>a:hover * {
  transition: all 0.3s;
  color: white;
}

.drop-down-opts-btns>a>img {
  width: 1.2rem;
  height: 1.2rem;
}

.drop-down-img-conatiner {
  position: relative;
  width: 20rem;
  height: 10rem;
  cursor: pointer;
}

.drop-down-img-conatiner:hover {
  opacity: 0.8;
}

.drop-down-img-conatiner>img {
  width: 100%;
  height: 100%;
}

.drop-down-img-conatiner>h2 {
  position: absolute;
  font-family: var(--inter);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.drop-down-img-conatiner>h2 {
  top: 0;
  left: 0;
  padding: 1rem 1.5rem;
  font-size: 1.125rem;
  color: white;
}

.drop-down-img-conatiner>p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem 1.5rem;
  color: white;
}