.connect-background {
  background: var(--connect-1on1-bg);
}

.connect-working {
  max-width: 60rem;
  width: 90%;
  margin: auto;
}

.connect-working h1 {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: var(--h1-font-size);
  font-weight: 500;
  line-height: var(--line-height);
  margin: 0 auto 2rem auto;
  padding-top: 4rem;
  text-align: center;
}

.connect-working p {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: var(--montserrat);
  font-weight: 400;
  line-height: var(--line-height);
  margin: auto;
  margin-bottom: 5rem;
}

.connect-network {
  width: 100%;
  height: fit-content;
  /* background: var(--connect-1on1-section-bg); */
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5%;
}

.connect-network img {
  width: 45%;
  height: fit-content;
  object-fit: contain;
  background: var(--connect-1on1-img-bg);
}

.connect-network > div {
  width: 55%;
}

.connect-network h1 {
  color: var(--primary-color);
  font-family: var(--montserrat);
  font-size: var(--h1-font-size);
  font-weight: 500;
  line-height: 2rem;
}

.connect-network p {
  color: rgba(255, 255, 255, 0.7);
  text-align: left;
  font-family: var(--montserrat);
  font-weight: 400;
  line-height: var(--line-height);
  margin-top: 2rem;
}

.socials-container {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0;
}

.socials-container > button > img {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  background: transparent;
}

@media screen and (max-width: 830px) {
  .connect-working h1,
  .connect-network h1 {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .connect-network p {
    font-size: 1.2rem;
  }

  .connect-network {
    flex-direction: column;
  }

  .connect-network > img {
    width: 70%;
  }

  .connect-network > div {
    width: 90%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
