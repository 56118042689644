.contact-isa-bg {
  background: var(--contact-isa-bg);
}

.custom-date-input::-webkit-calendar-picker-indicator,
.custom-time-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type="date"],
input[type="time"],
select[name="state"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-height: 2.3rem;
  padding: 12px;
  font-size: 1rem;
  box-sizing: border-box;
  background: var(--login-input-bg);
  border: 1px solid var(--heading-color-1);
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  appearance: initial;
  bottom: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* For Firefox */
.custom-date-input::-moz-calendar-picker-indicator,
.custom-time-input::-moz-calendar-picker-indicator {
  filter: invert(1);
}

/* For Microsoft Edge */
.custom-date-input::-ms-clear,
.custom-time-input::-ms-clear {
  display: none;
}

.custom-date-input,
.custom-time-input {
  color: white;
}


@media screen and (max-width: 767px) {

  input[type="date"],
  input[type="time"] {
    min-height: 2.3rem;
  }
}